import { BotListConfig, EmployeeCampaign, IAdvancedSettings, IBotDetail } from 'interface';
import { createContext, useEffect, useState } from 'react';

import { Source } from 'constant';
import { SourceProperty } from 'screens/BotStudio/BotList/interfaces';
import { defaultBotDetail, defaultBotListConfig } from 'screens/BotStudio/Defaults';
import isNil from 'lodash/isNil';
import useDrawer from 'hooks/useDrawer';
import { useFetch } from 'use-http';
import { BotContextType } from 'types/context';
import { isBotHavingAnySource } from 'utils/botUtils';
import useUser from 'hooks/useUsers';

export const BotContext = createContext<BotContextType>({} as BotContextType);

const BotProvider = ({ children }) => {
    const { post } = useFetch(process.env.REACT_APP_API_URL_PY);

    const [botListConfig, setBotListConfig] = useState<BotListConfig>(defaultBotListConfig);
    const [botDetailString, setBotDetailString] = useState();

    const [botDetail, setBotDetail] = useState<IBotDetail>(defaultBotDetail);
    const [activeSourceId, setActiveSourceId] = useState<number>(null);
    const [sourceDetail, setSourceDetail] = useState<SourceProperty[]>([]);

    const [candidateBotId, setCandidateBotId] = useState<string>(null);
    const [isEnabledCustomSource, setIsEnabledCustomSource] = useState<boolean>(!isNil(activeSourceId));
    const [isAllSourceRetrain, setIsAllSourceRetrain] = useState<boolean>(false);
    const [documentLoading, setDocumentLoading] = useState(false);
    const [staticLoading, setStaticLoading] = useState(false);
    const [trainedBotsLoading, setTrainedBotsLoading] = useState(false);
    const [addSessionLoading, setAddSessionLoading] = useState(false);
    const [documentDownloadLoading, setDocumentDownloadLoading] = useState(false);
    const [undoSourceActionLoading, setUndoSourceActionLoading] = useState(false);
    const [createBotSessionLoading, setCreateBotSessionLoading] = useState(false);
    const [sessionsByBotIdLoading, setSessionsByBotIdLoading] = useState(false);
    const [deleteSessionByBotIdLoading, setDeleteSessionByBotIdLoading] = useState(false);
    const [trainBotLoading, setTrainBotLoading] = useState(false);
    const [captchaResultLoading, setCaptchaResultLoading] = useState(false);
    const [candidateChatQuery, setCandidateChatQuery] = useState<{ id: string; text: string }>(null);
    const [isUpdateChatQuery, setIsUpdateChatQuery] = useState<boolean>(false);
    const [users, loading, empty, fetchUsers] = useUser('');

    const userNames = {};
    users.forEach((user) => {
        userNames[user.id.toString()] = user.name;
    });
    const [enableTrainButton, setEnableTrainButton] = useState(false);
    const [expandedRowBotIds, setExpandedRowBotIds] = useState<string[]>([]);

    const [tabList, setTabList] = useState<{ botId: string; tab: number }[]>([]);
    const [botThemeColor, setBotThemeColor] = useState(null);

    const [AdvancedSettingsData, setAdvancedSettings] = useState<IAdvancedSettings>({
        chunkTechnique: {},
        embeddingModel: [],
        retrieverMatchingTechnique: {},
        knowledgeSourceLlm: [],
        intentMatchingLlm: []
    });
    const [AdvancedSettingsLoading, setAdvancedSettingsLoading] = useState(false);

    const [isColorPicked, setIsColorPicked] = useState(false);

    const [LLM_TYPES, setLLM_TYPES] = useState([]);
    const DefaultResponseLLM = LLM_TYPES?.length > 0 ? LLM_TYPES[0] : 'azure-gpt4o-200b';

    useEffect(() => {
        const hasAnySource = isBotHavingAnySource(botDetail);

        setIsEnabledCustomSource(hasAnySource);
        if (!hasAnySource) setActiveSourceId(null);

        setSourceDetail([
            // {
            //     id: Source.WEBSITE,
            //     name: 'Website',
            //     icon: 'fa-light fa-browser',
            //     count: botDetail.websites.length
            // },
            {
                id: Source.DOCUMENT,
                name: 'Document',
                icon: 'fa-light fa-file-lines',
                count: botDetail.documents.filter((doc) => !doc.isDeleted).length
            },
            {
                id: Source.STATIC,
                name: 'Static Documents',
                icon: 'fa-light fa-file-lines',
                count: botDetail.staticKbs.filter((doc) => !doc.isDeleted).length
            }
            // {
            //     id: Source.API,
            //     name: 'API',
            //     icon: 'fa-light fa-sitemap',
            //     count: botDetail.apis.length
            // }
        ]);
    }, [botDetail]);

    const get_LLM = async () => {
        const response = await post('/Bot/GetLLMs', {});
        const isSuccess = response?.statusCode === 200;

        if (response?.statusCode === 200) {
            setLLM_TYPES(response?.data?.llms);
            //setTrainedBots(response?.data?.trainedBotDetails);
        } else {
            //t/oast.error(response.message);
        }
    };
    useEffect(() => {
        // get_LLM();

    }, []);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [isEditBot, setIsEditBot] = useState(false);
    const [testBotId, setTestBotId] = useState(null);

    const [showCreateBotModal, setShowCreateBotModal] = useState(false);
    const [showCloneBotModal, setShowCloneBotModal] = useState(false);
    const [refreshBot, setRefreshBots] = useState(false);
    const [searchBotQuery, setSearchBotQuery] = useState('');

    const [showCodeSnippetModal, setShowCodeSnippetModal] = useState(false);
    const [codeSnippet, setCodeSnippet] = useState('');
    const [drawerOpen, toggleDrawer] = useDrawer();
    const [trainedBots, setTrainedBots] = useState([]);

    const [displayBotDetail, setDisplayBotDetail] = useState<IBotDetail[]>([]);
    const [totalDisplayBotDetail, setTotalDisplayBotDetail] = useState(null);
    const [botResponseLLM, setBotResponseLLM] = useState<string>();
    const [isClone, setisClone] = useState(null); // TODO: this should be change as a separate variable but for now, i am just using one variable, Null-> default , False -> Move , True -> Cloned

    const [botInfoLoading, setBotInfoLoading] = useState(false);
    const [botReplyLoading, setBotReplyLoading] = useState(false);

    const [employeeCampaigns, setEmployeeCampaigns] = useState<EmployeeCampaign[]>([]);
    const [employeeCampaignsLoading, setEmployeeCampaignsLoading] = useState(false);

    const refreshBots = () => {
        setRefreshBots((prev) => !prev);
    };

    return (
        <BotContext.Provider
            value={{
                botDetail,
                setBotDetail,
                expandedRowBotIds,
                setExpandedRowBotIds,
                searchBotQuery,
                setSearchBotQuery,
                isEditBot,
                setIsEditBot,
                activeSourceId,
                setActiveSourceId,
                showCreateBotModal,
                setShowCreateBotModal,
                sourceDetail,
                showCodeSnippetModal,
                setShowCodeSnippetModal,
                codeSnippet,
                setCodeSnippet,
                drawerOpen,
                toggleDrawer,
                trainedBots,
                setTrainedBots,
                displayBotDetail,
                setDisplayBotDetail,
                totalDisplayBotDetail,
                setTotalDisplayBotDetail,
                botResponseLLM,
                setBotResponseLLM,
                isClone,
                setisClone,
                showCloneBotModal,
                setShowCloneBotModal,
                refreshBot,
                handleRefreshBots: refreshBots,
                botInfoLoading,
                setBotInfoLoading,
                candidateBotId,
                setCandidateBotId,
                isEnabledCustomSource,
                setIsEnabledCustomSource,
                testBotId,
                setTestBotId,
                hasUnsavedChanges,
                setHasUnsavedChanges,
                employeeCampaignsLoading,
                setEmployeeCampaignsLoading,
                employeeCampaigns,
                setEmployeeCampaigns,
                botListConfig,
                setBotListConfig,
                botDetailString,
                setBotDetailString,
                documentLoading,
                setDocumentLoading,
                staticLoading,
                setStaticLoading,
                trainedBotsLoading,
                setTrainedBotsLoading,
                botReplyLoading,
                setBotReplyLoading,
                addSessionLoading,
                setAddSessionLoading,
                documentDownloadLoading,
                setDocumentDownloadLoading,
                tabList,
                setTabList,
                botThemeColor,
                setBotThemeColor,
                isColorPicked,
                setIsColorPicked,
                LLM_TYPES,
                DefaultResponseLLM,
                AdvancedSettingsLoading,
                setAdvancedSettingsLoading,
                AdvancedSettingsData,
                setAdvancedSettings,
                undoSourceActionLoading,
                setUndoSourceActionLoading,
                isAllSourceRetrain,
                setIsAllSourceRetrain,
                createBotSessionLoading,
                setCreateBotSessionLoading,
                sessionsByBotIdLoading,
                setSessionsByBotIdLoading,
                deleteSessionByBotIdLoading,
                setDeleteSessionByBotIdLoading,
                trainBotLoading,
                setTrainBotLoading,
                enableTrainButton,
                setEnableTrainButton,
                captchaResultLoading,
                setCaptchaResultLoading,
                candidateChatQuery,
                setCandidateChatQuery,
                isUpdateChatQuery,
                setIsUpdateChatQuery,
                users,
                userNames
            }}>
            {children}
        </BotContext.Provider>
    );
};

export default BotProvider;
