import React, { createContext, useState, useCallback } from 'react';
import JwtBaseRoutes from './JwtBaseRoutes';
import { SidebarProvider } from '../Provider/SidebarProvider';
import { HeaderProvider } from '../Provider/HeaderProvider';
import JwtAuthProvider from '../Provider/JwtAuthProvider';
import { useFetch } from 'use-http';

interface AuthContextProps {
    token: string | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProviderComponent = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));

    const { post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL, {
        //headers: { Authorization: `${accessToken}` },
        cache: 'no-cache'
    });

    const login = useCallback(async (username: string, password: string) => {
        try {
            const response = await fetch('/token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });

            if (response.ok) {
                const data = await response.json();
                const newToken = data.access_token;
                localStorage.setItem('token', newToken);
                setToken(newToken);
            } else {
                throw new Error('Failed to authenticate');
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    }, []);

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        setToken(null);
    }, []);

    const isAuthenticated = !!token;

    return <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>{children}</AuthContext.Provider>;
};

interface AuthContainerProps {
    baseUrl: string;
    identityServerUrl: string;
    client: string;
    logo: any;
    loader: any;
    children?: React.ReactNode;
}

const JWTAuthContainer = ({ baseUrl, identityServerUrl, client, loader, logo, children }: AuthContainerProps) => {
    return (
        <AuthProviderComponent>
            <SidebarProvider>
                <JwtAuthProvider {...{ identityServerUrl, client, baseUrl }}>
                    <HeaderProvider {...{ loader, logo }}>
                        <JwtBaseRoutes {...{ identityServerUrl, client }}>{children}</JwtBaseRoutes>
                    </HeaderProvider>
                </JwtAuthProvider>
            </SidebarProvider>
        </AuthProviderComponent>
    );
};

export default JWTAuthContainer;
