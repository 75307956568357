import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './Login';
import { useAuth } from '../Hooks/useAuth';
import SecureRoute from './SecureRoute';
import TwoFA from './TwoFA';
import SetPasswordPage from './SetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

interface RoutesModuleProps {
    identityServerUrl: string;
    client: string;
    children?: React.ReactNode;
}

const JwtBaseRoutes = ({ children, ...props }: RoutesModuleProps) => {
    const { logout } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const isAuthRoute = location.pathname.includes('/auth/');

        if (!token && !isAuthRoute) logout();
    }, [location.pathname, logout]);

    return (
        <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/2FA" element={<TwoFA />} />
            <Route path="/auth/set-password" element={<SetPasswordPage />} />
            <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/auth/reset-password" element={<ResetPasswordPage />} />

            <Route
                path="/*"
                element={
                    <SecureRoute {...{ identityServerUrl: props.identityServerUrl, client: props.client }}>
                        {children}
                    </SecureRoute>
                }
            />
        </Routes>
    );
};

export default JwtBaseRoutes;
