import { useState } from 'react';
import logo from 'assets/images/logo.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { Checkbox } from 'primereact/checkbox';

const SetPasswordPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get('token') || null;
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [twoFa, setTwoFa] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<string[]>([]);

    const { get, post, loading } = useFetch(process.env.REACT_APP_CONVERSE_IAM_URL);

    const getPasswordErrorMessage = () => {
        if (password.length < 8) return 'Password must be at least 8 characters long.';
        if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter.';
        if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter.';
        if (!/\d/.test(password)) return 'Password must contain at least one number.';
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return 'Password must contain at least one special character.';
        return '';
    };

    const handleSubmit = async () => {
        let hasError = false;
        let errorMessages: string[] = [];

        const passwordErrorMessage = getPasswordErrorMessage();
        if (passwordErrorMessage) {
            errorMessages.push(passwordErrorMessage);
            hasError = true;
        }

        if (password !== confirmPassword) {
            errorMessages.push('Passwords do not match');
            hasError = true;
        }

        if (!name) {
            errorMessages.push('Full name is required');
            hasError = true;
        }

        if (!token) {
            errorMessages.push('Invalid token');
            hasError = true;
        }

        if (hasError) {
            setErrors(errorMessages);
            return;
        }

        setErrors([]);

        const response = await post('/set_password', { token, password, name, twoFa: twoFa ?? false });
        if (response.response_code === 200) {
            toast.success('Password set successfully');
            navigate('/auth/login');
        } else {
            setErrors([response.message || 'Failed to set password']);
        }
    };

    return (
        <div className="login">
            <div className="login-form">
                <div className="form">
                    <div className="form__header">
                        <img src={logo} alt="Bot" />
                        <h2>Complete your profile</h2>
                    </div>

                    <div className="login__inner">
                        <div className="form__group">
                            <label htmlFor="name" className="form__label">
                                Set Name
                            </label>
                            <div className="form__field">
                                <InputText
                                    id="name"
                                    value={name}
                                    onChange={(e) => {
                                        setErrors([]);
                                        setName(e.target.value);
                                    }}
                                    className="form__input"
                                    placeholder="Enter your full name"
                                />
                            </div>
                        </div>
                        <div className="form__group">
                            <label htmlFor="password" className="form__label">
                                Set Password
                            </label>
                            <div className="form__field">
                                <InputText
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        setErrors([]);
                                        setPassword(e.target.value);
                                    }}
                                    className="form__input"
                                    placeholder="Enter Password"
                                />
                            </div>
                        </div>

                        <div className="form__group">
                            <label htmlFor="confirmPassword" className="form__label">
                                Confirm Password
                            </label>
                            <div className="form__field">
                                <InputText
                                    id="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setErrors([]);
                                        setConfirmPassword(e.target.value);
                                    }}
                                    className="form__input"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            {errors.length > 0 && (
                                <ul className="error-bubble error-message">
                                    {errors.map((error, index) => (
                                        <li key={index}>• {error}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="form__group">
                            <div className="form__label">Enable Two Factor Authentication</div>
                            <div className="form__field">
                                <Checkbox checked={twoFa} onChange={(e) => setTwoFa(e.checked)} />
                            </div>
                        </div>

                        <div className="form__group">
                            <Button
                                label={loading ? 'Submitting...' : 'Submit'}
                                onClick={handleSubmit}
                                className="form__button"
                                disabled={loading}
                                icon={loading ? 'pi pi-spin pi-spinner' : ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPasswordPage;
