import { useFetch } from 'use-http'
import { useCallback, useEffect, useState } from 'react';

const useUser = (searchQuery = ''): [users: any[], isLoading: boolean, emptyUser: boolean, fetchUsers: () => {}] => {
  const { get, loading: fetchLoading } = useFetch(`${process.env.REACT_APP_CONVERSE_IAM_URL}/api`);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emptyUser, setEmptyUser] = useState(false);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const queryParams = searchQuery ? `?search=${searchQuery}` : '';
      const response = await get(`/users${queryParams}`);
      setUsers(response || []);
      setEmptyUser((response || []).length === 0);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
      setEmptyUser(true);
    } finally {
      setIsLoading(false);
    }
  }, [get, searchQuery]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, get]);

  // Combine fetch loading and manual loading state
  const loading = isLoading || fetchLoading;

  return [users, loading, emptyUser, fetchUsers];
};

export default useUser;
