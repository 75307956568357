import { useNavigate, useLocation, useParams, Link, Route, Routes, Outlet, Navigate } from 'react-router-dom';

import React, { Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import BotProvider from 'providers/BotProvider';
import { SessionProvider } from '../providers/SessionProvider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Loader from 'components/Loader';
import PermissionsProvider from 'providers/PermissionsProvider';
import useSignalRConnection from 'hooks/useSignalRConnection';
import SignalRConnectionProvider from 'providers/SignalRConnectionProvider';
import AgentAssistProvider from 'providers/AgentAssistProvider';
import { SurveyProvider } from 'providers/SurveyFlowProvider';
import { useAuthContext } from 'auth/Provider/JwtAuthProvider';
import { Header } from 'auth/Layout/Header'
import Layout from 'auth/Layout';
import JwtHttpProvider from 'auth/Provider/JwtHttpProvider';
import { SideBarItem } from 'auth/Layout/SideBar';
import MiniSideBar from 'auth/Layout/MiniSidebar';
import AccessDenied from 'auth/Component/AccessDenied';


//const { sessionPermission } = useContext(PermissionContext);
const SideBarDefaultItems: SideBarItem[] = [
    {
        icon: 'fa-light fa-message-bot',
        name: 'Bot Studio',
        route: 'botstudio',
        component: React.lazy(() => import('./BotStudio/BotList')),
        unique_id: 'report_info_bot',
        showInSidebar: true,
        description: '',
        children: [],
        child: false,
        externalLink: false,
        path: '/botstudio'
    },
    {
        icon: 'far fa-pencil-paintbrush',
        name: 'Editor',
        route: 'edit',
        externalLink: false,
        component: React.lazy(() => import('./BotStudio/Routes/BotEdit')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        path: '/edit/:botId',
        children: [
            {
                icon: 'far fa-pencil-paintbrush',
                name: 'Editor',
                route: 'edit',
                externalLink: false,
                component: React.lazy(() => import('./BotStudio/CreateBot/Sources')),
                unique_id: 'report_info_bot',
                showInSidebar: true,
                description: '',
                path: 'sources',
                children: [],
                child: true
            }
        ],
        child: false
    },
    // {
    //     icon: 'fa-solid fa-message-bot',
    //     name: 'Sessions',
    //     route: 'sessions/?',
    //     component: React.lazy(() => import('./Chat/LeftSidebar')),
    //     unique_id: 'report_info_bot',
    //     showInSidebar: true,
    //     description: '',
    //     child: false,
    //     externalLink: false,
    //     path: '/sessions/?',

    {
        icon: 'fa-light fa-user',

        name: 'Chat',
        route: 'conversebot/:botId',
        component: React.lazy(() => import('./Chat/TryoutChatbot')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        child: false,
        externalLink: false,
        path: '/conversebot/:botId',
        children: []
    },
    {
        icon: 'fa-light fa-user',
        name: 'Chat',
        route: 'conversebot/:sessionId',
        component: React.lazy(() => import('./Chat/TryoutChatbot')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        child: false,
        externalLink: false,
        path: '/conversebot/:sessionId',
        children: []
    },
    {
        icon: 'fal fa-users-gear',
        name: 'IAM',
        route: 'iam',
        path: '/iam',
        showInSidebar: true,
        externalLink: true,
        children: [],
        child: false,
        description: 'Set up clients, users and assign them roles and feature permissions.',
        component: React.lazy(() => import('./Admin')),
        unique_id: 'report_Configuration'
    },
    {
        icon: 'fa-regular fa-message-pen',
        name: 'Feedback Reports',
        route: 'feedbackReports',
        path: '/feedbackReports',
        showInSidebar: true,
        externalLink: true,
        children: [],
        child: false,
        description: 'Set up clients, users and assign them roles and feature permissions.',
        component: React.lazy(() => import('./Report/FeedbackReport')),
        unique_id: 'report_Configuration'
    },
    {
        icon: 'fa-regular fa-message-pen',
        name: 'Feedback',
        route: '/feedback/:sessionId',
        component: React.lazy(() => import('./Report/FeedbackReport/SMEFeedbackReview')),
        unique_id: 'report_Configuration',
        showInSidebar: false,
        description: '',
        children: [],
        child: false,
        externalLink: true,
        path: '/feedback/:session_id'
    }
    // {
    //     icon: 'fa-light fa-users',
    //     name: 'Clients',
    //     route: 'admin',
    //     component: React.lazy(() => import('./Admin/CreateClient')),
    //     unique_id: 'report_Configuration',
    //     showInSidebar: true,
    //     description: '',
    //     children: [],
    //     child: false,
    //     externalLink: true,
    //     path: '/admin/clients'
    // },
    // {
    //     icon: 'fa-light fa-file-invoice',
    //     name: 'Accounts',
    //     route: 'admin',
    //     component: React.lazy(() => import('./Admin/CreateAccount')),
    //     unique_id: 'report_Configuration',
    //     showInSidebar: true,
    //     description: '',
    //     children: [],
    //     child: false,
    //     externalLink: true,
    //     path: '/admin/accounts'
    // }

];

const baseOriginUrl = process.env.REACT_APP_BASE_ORIGIN_URL;
const modules = ['feedback', 'converse', 'analyze'];
function Home() {
    const { routes, loading } = useAuthContext();


    return (
        <Fragment>
            {loading && <Loader />}
            <Header {...{ toast, menuItems: [], baseOriginUrl, appName: '' }}> <></></Header>
            <MiniSideBar {...{ routes }} />
            {/* <JwtHttpProvider> */}
            <PermissionsProvider>
                <SignalRConnectionProvider>
                    <BotProvider>
                        <AgentAssistProvider>
                            <SurveyProvider>
                                <SessionProvider>
                                    <Layout {...{ modules, SideBarDefaultItems, appName: process.env.REACT_APP_TITLE }}>
                                        <Routes>
                                            <Route path="/" element={<Navigate to="/botstudio" />} />
                                        </Routes>
                                        <Outlet />
                                        <ConfirmDialog />
                                        <ToastContainer
                                            position={'bottom-right'}
                                            autoClose={2000}
                                            hideProgressBar={false}
                                            closeOnClick={true}
                                            pauseOnHover={true}
                                            draggable={true}
                                            pauseOnFocusLoss={false}
                                        />
                                    </Layout>
                                </SessionProvider>
                            </SurveyProvider>
                        </AgentAssistProvider>
                    </BotProvider>
                </SignalRConnectionProvider>
            </PermissionsProvider>
            {/* </JwtHttpProvider> */}
        </Fragment>
    );
}
export default Home;
