import React, { useMemo } from 'react';
import { CachePolicies, Provider } from 'use-http';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'auth/Hooks/useAuth';

const JwtHttpProvider = ({ children, apiUrl }: { children: React.ReactNode; apiUrl: string }) => {
  const navigate = useNavigate();
  const { getToken, logout } = useAuth();

  const options = useMemo(() => ({
    cacheLife: 0,
    cachePolicy: CachePolicies.NO_CACHE,
    interceptors: {
      request: async ({ options }) => {
        const token = getToken();
        if (token) {
          options.headers.authorization = `Bearer ${token}`;
        }
        options.headers["Environment"] = process.env.REACT_APP_ENV;
        return options;
      },
      response: async ({ response }) => {
        if (response.status === 401) {
          logout();
        }

        // // Log all headers for debugging
        // response.headers.forEach((value, key) => {
        //   console.log(`Header ${key}:`, value);
        // });

        const processingTime = response.headers.get('processing-time') || response.headers.get('Processing-Time');
        const modelInferenceTime = response.headers.get('model-inference-time') || response.headers.get('Model-Inference-Time');

        if (processingTime) {
          localStorage.setItem('processing-time', processingTime);
        } else {
          console.warn('Processing time header not found');
        }

        if (modelInferenceTime) {
          localStorage.setItem('model-inference-time', modelInferenceTime);
        } else {
          console.warn('Model inference time header not found');
        }

        return response;
      },
    },
  }), [getToken, logout]);

  return <Provider url={apiUrl} options={options} children={children} />;
};

export default JwtHttpProvider;
