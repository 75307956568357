import logo from 'assets/images/logo.svg';
import loader from 'assets/images/loader.gif';
import Home from 'screens/Home';
import 'react-datepicker/dist/react-datepicker';
import 'react-phone-number-input/style.css'
import JWTAuthContainer from './auth/Component/JwtAuthContainer';
import JwtHttpProvider from './auth/Provider/JwtHttpProvider';
import { datadogRum } from '@datadog/browser-rum';

const REACT_APP_NAME = process.env.REACT_APP_NAME;
const API_URL = process.env.REACT_APP_API_URL_PY;

function App() {

    var pathname = window.location.pathname.slice(0, window.location.pathname.length - 1);

    let Originurl = window.location.origin.toString();
    if (!Originurl.includes('localhost')) {
        datadogRum.init({
            applicationId: 'f1f6ed55-44af-448f-9dca-0c0f11583365',
            clientToken: 'pub177de120b5ed885e2dd477f85fcb8d67',
            site: 'datadoghq.com',
            service: process.env.REACT_APP_DATADOG_NAME,
            env: process.env.REACT_APP_ENV,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sampleRate: 100,
            sessionReplaySampleRate: 100,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow'
        });
    }

    return (
        <JwtHttpProvider {...{ apiUrl: API_URL }}>
            <JWTAuthContainer
                {...{
                    baseUrl: `/${REACT_APP_NAME}`,
                    baseOriginUrl: process.env.REACT_APP_BASE_URL,
                    client: 'SSO',
                    identityServerUrl: process.env.REACT_APP_IDENTITY_URL,
                    logo,
                    loader
                }}>
                <Home />
            </JWTAuthContainer>
        </JwtHttpProvider>
    );
}

export default App;
